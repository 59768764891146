import React from 'react'

const CommentNavBar = (props) => {

  return (
    <div className="comment-navbar-container">
      <div className="footer">
        <button type="button" className="comment-button"><span className="comment-icon"><i className="far fa-comment"></i></span></button>
      </div>
    </div>
  )
}

export default CommentNavBar