import React from 'react'
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import L from 'leaflet'

// eslint-disable-next-line
import BeautifyIcon from 'beautifymarker'

// actions redux
import { setMapReady } from '../../actions/index'
import { setSteps } from '../../actions/index'
import { setTracks } from '../../actions/index'
import { setSelectedTrackId } from '../../actions/index'
import { setSelectedStepId } from '../../actions/index'
import { setSelectedPoint } from '../../actions/index'

// variables de contexte
import { DEFAULT_MAPCENTER, DEFAULT_ZOOM, WEIGHT_TRACK, OPACITY_TRACK, IGN_API_KEY } from '../../commons.js'

// Hook useEffect displayMap pour afficher la carte
// Hook useEffect displaySteps lié à steps pour l'affichage des étapes
// Hook useEffect displayTracks lié à tracks pour l'affichage des tracés
// Hook useEffect displayCurrentStep lié à currentStep pour l'affichage de l'étape courante

const TravelMap = (props) => {

  const { mapReady, setMapReady, currentStep, steps, setSelectedStepId, tracks, setSelectedTrackId, setSelectedPoint } = props

  let baseMaps = []

  // OSM
  let OSM_URL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  let OSM_ATTRIB = '&copy; <a href="https://www.openstreetmap.org/copyright/">OpenStreetMap</a> contributors';
  let OSM_LAYERS_OPTIONS = { minZoom: 1, maxZoom: 18, attribution: OSM_ATTRIB }
  baseMaps['OpenStreetMap'] = new L.TileLayer(OSM_URL, OSM_LAYERS_OPTIONS)

  // OpenTopoMap
  OSM_URL = 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png';
  OSM_ATTRIB = '&copy; <a href="https://www.opentopomap.org/">OpenTopoMap</a> contributors';
  OSM_LAYERS_OPTIONS = { minZoom: 1, maxZoom: 18, attribution: OSM_ATTRIB }
  baseMaps['OpenTopoMap'] = new L.TileLayer(OSM_URL, OSM_LAYERS_OPTIONS)

  // CyclOSM
  OSM_URL = 'https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png';
  OSM_ATTRIB = '&copy; <a href="https://www.cyclosm.org/copyright/">CyclOSM</a> contributors';
  OSM_LAYERS_OPTIONS = { minZoom: 1, maxZoom: 18, attribution: OSM_ATTRIB }
  baseMaps['CyclOSM'] = new L.TileLayer(OSM_URL, OSM_LAYERS_OPTIONS)
  

  // ESRI World Topographic Map (https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer)
  let ESRI_URL = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}'
  let ESRI_ATTRIB = '&copy; <a href="https://esriurl.com/WorldTopographicMapContributors">Esri World Topographic Map</a> contributors'
  let ESRI_LAYERS_OPTIONS = { minZoom: 3, maxZoom: 20, attribution: ESRI_ATTRIB }

  baseMaps['ESRI Topo Map'] = new L.TileLayer(ESRI_URL, ESRI_LAYERS_OPTIONS)

  // ESRI World Imagery (https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer)
  ESRI_URL = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
  ESRI_ATTRIB = '&copy; <a href="https://esriurl.com/WorldImageryContributors">Esri World Imagery</a> contributors'
  ESRI_LAYERS_OPTIONS = { minZoom: 3, maxZoom: 20, attribution: ESRI_ATTRIB }

  baseMaps['ESRI Imagery'] = new L.TileLayer(ESRI_URL, ESRI_LAYERS_OPTIONS)


  // IGN 25 Topo Standard
  const IGN_URL = 'http://wxs.ign.fr/' + IGN_API_KEY + '/wmts'
  const IGN_URL_OPTIONS = '&EXCEPTIONS=text/xml&FORMAT=image/jpeg&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&&TILEMATRIX={z}&TILECOL={x}&TILEROW={y}'
  const IGN_ATTRIB = '&copy; <a href="http://www.ign.fr/">IGN</a>'
  const IGN_LAYERS_OPTIONS = { minZoom: 3, maxZoom: 20, attribution: IGN_ATTRIB }

  let wmtsUrl = `${IGN_URL}?LAYER=GEOGRAPHICALGRIDSYSTEMS.MAPS${IGN_URL_OPTIONS}`;
  baseMaps['IGN Topo Standard'] = new L.tileLayer( wmtsUrl, IGN_LAYERS_OPTIONS )

  // IGN SCAN25 Topo Scan Express Standard
  wmtsUrl = `${IGN_URL}?LAYER=GEOGRAPHICALGRIDSYSTEMS.MAPS.SCAN-EXPRESS.STANDARD${IGN_URL_OPTIONS}`;
  baseMaps['IGN Topo Express'] = new L.tileLayer( wmtsUrl, IGN_LAYERS_OPTIONS )

  // IGN Photos aériennes
  wmtsUrl = `${IGN_URL}?LAYER=ORTHOIMAGERY.ORTHOPHOTOS${IGN_URL_OPTIONS}`;
  baseMaps['IGN Photos'] = new L.tileLayer( wmtsUrl, IGN_LAYERS_OPTIONS )


  // Icones de la carte
  delete L.Icon.Default.prototype._getIconUrl

  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
  })

  const markerOffOptions = {
    icon: 'bicycle',
    borderColor: '#025785',
    textColor: '#025785',
    backgroundColor: '#fff',
    borderWidth: 2,
    iconSize: [27, 27],
    innerIconAnchor: [0, 6]
  }

  const iconOff = L.BeautifyIcon.icon(markerOffOptions)

  const markerOnOptions = {
    icon: 'bicycle',
    borderColor: '#cc0000',
    textColor: '#cc0000',
    backgroundColor: '#fff',
    borderWidth: 3,
    iconSize: [32, 32],
    innerIconAnchor: [0, 7]
  }

  const iconOn = L.BeautifyIcon.icon(markerOnOptions)

  // couche markers
  const [stepsLayer, setStepsLayer] = useState(null)
  // couche traces
  const [tracksLayer, setTracksLayer] = useState(null)
  // id de la carte
  const [map, setMap] = useState(null)
  // id du marker etape courante précédente
  const [previousStepLayerId, setPreviousStepLayerId] = useState(0)


  // Selection d'une étape sur la carte
  const onStepClick = (e) => {
    setSelectedStepId(e.layer.stepId)
  }
  // Selection d'une trace sur la carte
  const onTrackClick = (e) => {
    setSelectedTrackId(e.layer.trackId)
  }
  // Selection d'un point sur la carte
  const onPointClick = (e) => {
    const latlng = e.latlng.lat.toFixed(6) + ',' + e.latlng.lng.toFixed(6)
    setSelectedPoint(latlng)
  }
  

  // affichage de la carte initiale avec les marqueurs et traces
  useEffect(() => {

    const displayMap = async () => {

      try {

        // creation de la carte et afffichage des marqueurs
        let mapCenter = DEFAULT_MAPCENTER.split(',')
        if (steps.length) mapCenter = steps[0].latlng.split(',')

        let zoom = DEFAULT_ZOOM

        // création de la carte et ajout du gestionnaire d'évenement onPointClick 
        const map0 = L.map('map', {
          center: mapCenter,
          zoom: zoom,
          layers: baseMaps['CyclOSM']
        }).on("click", e => onPointClick(e))

        // ajout du selecteur de cartes
        L.control.layers(baseMaps,null).addTo(map0)

        // ajout de l'échelle
        L.control.scale({imperial: false}).addTo(map0)

        setMap(map0)

      } catch (error) {
        console.log(error)
      }
    }

    displayMap()

    // eslint-disable-next-line
  }, [])


  // affichage des markers associés aux étapes
  useEffect(() => {

    const displaySteps = async () => {

      try {

        // création de la couche steps
        if (map && !stepsLayer) {
          const stepsLayer0 = new L.featureGroup()
          setStepsLayer(stepsLayer0)
          // ajout de la couche des markers et ajout du gestionnaire d'évenement onStepClick
          stepsLayer0.addTo(map).on("click", e => onStepClick(e))
          setMap(map)
        }

        // actualisation des markers (ajout et suppression d'étapes)
        if (map && stepsLayer) {
          let updateStepsLayer = false
          let updateSteps = false

          // parcours des etapes pour prendre en compte l'ajout de nouvelles couches de type marker
          // cela ne prend pas en compte le changement de position d'un marker existant
          steps.forEach((step) => {
            // on verifie qu'il existe bien une couche de type marker associé à une étape
            if (!step.layerId) {
              // creation d'une couche de type marker
              const latlng = step.latlng.split(',')
              let stepLayer = L.marker(latlng, { icon: iconOff }).addTo(stepsLayer)
              // stockage de l'id de l'étape
              stepLayer.stepId = step.id
              // ajout du marker dans la couche stepsLayer
              stepsLayer.addLayer(stepLayer)
              updateStepsLayer = true
              // stockage de l'id leaflet du marker
              step.layerId = stepsLayer.getLayerId(stepLayer)
              updateSteps = true
            }
          })

          // parcours des couches de type markers pour prendre en compte la suppression d'étapes
          const layers = stepsLayer.getLayers()
          layers.forEach((layer) => {
            // on verifie que l'étape existe toujours
            const step = steps.find(step => step.id === layer.stepId)
            if (step === undefined) {
              // retrait de la couche car l'étape n'existe plus
              stepsLayer.removeLayer(layer)
              updateStepsLayer = true
              // suppression de la couche marker
              layer.remove()
            }
          })

          // mise à jour de la couche stepsLayer en mémoire
          if (updateStepsLayer) setStepsLayer(stepsLayer)

          // mise à jour des étapes en mémoire
          if (updateSteps) setSteps(steps)

          // mise à jour de l'état de la carte
          if (!mapReady) { 
            setMapReady(true)
            // déplacement vers la dernière étape
            //if (steps.length) setSelectedStepId( steps[steps.length - 1].id )
            // déplacement vers la première étape
            if (steps.length) setSelectedStepId( steps[0].id )
          }

        }

      } catch (error) {
        console.log(error)
      }
    }

    displaySteps()

    // eslint-disable-next-line
  }, [steps, stepsLayer, map])


  // affichage des traces
  useEffect(() => {

    const displayTracks = async () => {

      try {

        // création de la couche tracks
        if (map && !tracksLayer) {
          const tracksLayer0 = new L.featureGroup()
          setTracksLayer(tracksLayer0)
          // ajout de la couche des traces et ajout du gestionnaire d'évenement onTrackClick
          tracksLayer0.addTo(map).on('click', (e)=> onTrackClick(e))
          setMap(map)
        }

        // actualisation des traces (ajout et suppression de traces gpx)
        if (map && tracksLayer) {
          let updateTracksLayer = false
          let updateTracks = false

          // parcours des traces pour prendre en compte l'ajout de nouvelles couches de type geojson
          tracks.forEach((track) => {
            // on verifie qu'il existe bien une couche associée à une trace
            if (!track.layerId) {
              // creation d'une couche de type geojson
              const featureCollection = JSON.parse(track.geojson)
              let trackLayer = L.geoJSON(featureCollection.features, { style: { "color": track.color, "weight": WEIGHT_TRACK, "opacity": OPACITY_TRACK } })
              // stockage de l'id de la trace au niveau de la couche
              trackLayer.eachLayer(layer => layer.trackId = track.id)
              // ajout de la couche dans le groupe
              tracksLayer.addLayer(trackLayer)
              updateTracksLayer = true
              // stockage de l'id leaflet de la trace
              track.layerId = tracksLayer.getLayerId(trackLayer)
              updateTracks = true
            }
          })

          // parcours des couches de type geojson pour prendre en compte la suppression de traces
          const layers = tracksLayer.getLayers()
          layers.forEach((layer) => {
            // on verifie que la trace existe toujours. On regarde le track.id de la première sous-couche
            const subLayers = layer.getLayers()
            const track = tracks.find(track => track.id === subLayers[0].trackId)
            if (track === undefined) {
              // retrait de la couche car la trace n'existe plus
              tracksLayer.removeLayer(layer)
              updateTracksLayer = true
              // suppression de la couche marker
              layer.remove()
            }
          })

          // mise à jour de la couche tracksLayer en mémoire
          if (updateTracksLayer) setTracksLayer(tracksLayer)

          // mise à jour des traces en mémoire
          if (updateTracks) setTracks(tracks)
        }

      } catch (error) {
        console.log(error)
      }
    }

    displayTracks()

    // eslint-disable-next-line
  }, [tracks, tracksLayer, map])


  // affichage ou changement de position de l'étape courante
  useEffect(() => {

    const displayCurrentStep = async () => {

      if (map && stepsLayer && currentStep) {
        // Mise à jour de la carte en fonction du marqueur courant
        // Appelé à chaque fois que currentStep est modifié

        // passage à OFF de la couleur du marqueur précédent
        if (previousStepLayerId) {
          const marker = stepsLayer.getLayer(previousStepLayerId)
          if (marker) marker.setIcon(iconOff)
        }

        // deplace le centre de la carte sur la nouvelle étape courante
        const mapCenter = currentStep.latlng.split(',')

        map.setView(mapCenter,
          map.getZoom(), {
          "animate": true,
          "pan": {
            "duration": 2
          }
        })

        // passage à ON de la couleur du nouveau marqueur courant
        if (currentStep.layerId) {
          const marker = stepsLayer.getLayer(currentStep.layerId)
          if (marker) marker.setIcon(iconOn)
          // déplacement éventuel du marqueur courant
          if (marker) marker.setLatLng(mapCenter)
          setPreviousStepLayerId(currentStep.layerId)
        }
      }
    }

    displayCurrentStep()

    // eslint-disable-next-line
  }, [currentStep])


  return (
    <div className="map-container">
      <div id="map"></div>
    </div>
  )
}

// Redux: mapping des props depuis le store
const mapStateToProps = state => {
  return {
    mapReady: state.mapReady,
    steps: state.steps,
    tracks: state.tracks,
    currentStep: state.currentStep
  }
}

// Redux: mapping action creators
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setMapReady: setMapReady,
    setSteps: setSteps,
    setTracks: setTracks,
    setSelectedStepId: setSelectedStepId,
    setSelectedTrackId: setSelectedTrackId,
    setSelectedPoint: setSelectedPoint
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelMap)