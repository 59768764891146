import React from 'react'
import { Link } from "react-router-dom"

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'


// action redux view slider (slider / scrollList)
import { setViewSlider } from '../../actions/index'

// action redux état de la carte
import { setMapReady } from '../../actions/index'

// affichage du nom du carnet courant

const TravelbookNavBar = (props) => {

    // carnet à éditer (update / delete) et état des menus
    const { currentTravelbook, viewSlider, setViewSlider, setMapReady } = props

    // switch viewer 
    const switchViewer = (e) => {
        e.preventDefault()
        setViewSlider(!viewSlider) // bascule le mode d'affichage des notes entre slider et scrollList
    }

    // ajout du bouton viewer toggle
    const displayToggleButton = () => {
        if (viewSlider) return ('fas fa-toggle-on')
        else return ('fas fa-toggle-off')
    }

    const leaveTravelbook = () => {
        // redirection vers la page d'accueil
        setMapReady(false)        
    }

    return (
        <div className="travelbook-navbar-container">
            <div className="navbar-container">
                <div className="title"><Link to="/" onClick={leaveTravelbook}><span>{currentTravelbook.name}</span></Link></div>
                <button type="button" className="toggle">
                    <span className="toggle-icon" onClick={switchViewer}>
                        <i className={displayToggleButton()}></i>
                    </span>
                </button>
            </div>
        </div>
    )
}

// Redux: mapping action creators - setTracks
// Mise à jour du menu carnet de voyage courant
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setViewSlider: setViewSlider,  // selection de la vue Slider / ScrollLlist
        setMapReady: setMapReady
    }, dispatch)
}

const mapStateToProps = state => {
    return {
        viewSlider: state.viewSlider
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelbookNavBar)