import React from 'react'
import { connect } from 'react-redux'

const StepNavBar = (props) => {

  // étape à éditer (add / update / delete) et état des menus
  const { currentStep } = props

  if (currentStep) {

    let tdate = ''
    if (currentStep.eventdate !== '0000-00-00 00:00:00') {
      let edate = new Date(currentStep.eventdate)
      const dateTimeFormat = new Intl.DateTimeFormat('fr-FR', { year: 'numeric', month: 'long', day: '2-digit' }) 
      const [{ value: day },,{ value: month },,{ value: year }] = dateTimeFormat.formatToParts(edate) 
      tdate = ` - ${day} ${month} ${year}`
    }

    return (
      <div className="step-navbar-container">
        <div className="navbar-container">
          <div className="title">{currentStep.title} <span className="tdate">{tdate}</span></div>
        </div>
      </div>
    )
  }
  else return ('')

}

const mapStateToProps = state => {
  return {
      currentStep: state.currentStep
  }
}

export default connect(mapStateToProps)(StepNavBar)