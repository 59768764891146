import React from "react"

// affichage du nom de l'application et du menu création d'un carnet

const HomeNavBar = (props) => {

    return (
        <div className="home-navbar-container">
            <div className="navbar-container">
                <div className="title">Travelsteps</div>
            </div>
        </div>
    )
}

export default HomeNavBar